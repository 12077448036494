:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1400px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1200px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: "Hind", sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Lato.css";
@import "/fontmodules/Amethysta.css";
@import "/fontmodules/Hind.css";

@import "/cssmodules/normalize.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/





.prudbild {
    display: block;
}


/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/

body {
    font-family: "Hind", "HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    line-height: 1.5;
    font-weight: 300;
    color: #303030;
    background: rgba(233, 206, 183, 0.36);
}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    width: 100%;
}
figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}
.pagecontent {
    background: #fff;
}

.button {
    display: block;
    margin: 0;
    color: white;
    background: #637459;
    font-family: "Amethysta";
    border: 0px;

}
.button:hover {
    color: white;
    background: #55654c;
    
}
.content-wrapper {
    background: #fff;
}


/* ------------------------------- FONTS + HEADINGS ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: "Amethysta";
    margin: 0.5em 0;  
}
.homepage h1 {
    font-size: 1em;
}

.slick-title {
    font-size: 1.6rem;
    font-family: Amethysta;
    font-weight: 600;
    text-align: center;
    min-width: 100%;
    color: rgba(255, 255, 255, 0.88);
    background: rgba(0, 0, 0, 0.62);
    padding: 0px 10px;
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
    text-transform: capitalize;
}


/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: calc(100vh - 80px);
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/*Facebook album*/
.album-bild {
    position:relative;
}
.album-bild img {
    display:block;
}
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#e4e4e4;  
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.9) 100%); 
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#e4e4e4;
}
.album-bild:hover .info {
    opacity:1; 
}
/* ---------------SLIDER----------- */


.slick {
    margin-bottom: 1rem;
    
}

.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;
    
} 

.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 56vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
}



/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */
.starttext h1 {
    margin-top: 0px;
}





/* ------------------STARTBOXARNA----------- */
.startboxar {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
}


.boxwrapper {
    min-height: 30vh;
    background-size: 0;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption  {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.boxwrapper[style]:before  {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .1s ease-in 0s;
}

.boxwrapper figure img {
    transition: transform .1s ease-in 0s;
}

.boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .1s ease-in 0s;
    z-index: 2;
}


.boxwrapper:hover:after {
    background: rgba(255,255,255,0.2);
    transition: background .1s ease-in 0s;

}

.boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1);
    transition: transform .1s ease-in 0s;
    filter: blur(1px);
}

.boxwrapper h2 {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 0.8rem;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    font-size: 1.2rem;
    z-index: 3;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.84);
    text-transform: uppercase;
}
/* ------------------PRODUKT/ER----------- */

.readmore {
    text-decoration: underline;
    color: #3b5998;
}

.produkter .button {
    display: inline-block;
    float: right;
}







/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */
header {
    background: #f7ede5;
    z-index: 10;
    width: 100%;
    position: relative; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px 0px;
}

.logo {
    padding: 10px 0px;
}
.logo img {
    max-height: 50px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}

header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    font-size: 1.1em;
    letter-spacing: 2px;
    font-weight: 300;
    text-transform: uppercase;
    color: #a4a298;
}
.menu.menu li a:hover, .menu.menu li.active a {
}

#menu-icon {
    color: #b38770;
}



/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/

/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
        header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }


}
    
    




/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: #000;
    padding: 30px 0;
}
footer, footer a, footer a:hover { color:#fff; }
footer svg {
    margin-right: 10px;
    fill: white;
}
footer h4 {margin-top:0;}

/* -----------------Intendit-footer---------------- */
.created-by {
    background: #000;
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #717171;
    line-height:1.1;
}
p.intenditfooter {
   margin:0;
}
/* -----------------KONTAKT---------------- */

/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */
.newsitem > div .button {
    background: #000;
    color: white;
    border: 1px solid black;
}

.personalitem img {
    border-radius: 50%;
}

.imagetop {
    min-height: 45vh;
    background-size: cover;
    background-position: center top;
}


/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #404040;
}

/* -----------------KONTAKTFORMULÄR---------------- */
/* -----------------KONTAKTFORMULÄR---------------- */

.boltform form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.boltforms-row {
    flex-basis: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.boltforms-six-row {
    flex-basis: 50%;
}

.boltforms-four-row {
    flex-basis: 33%;
}

.boltforms-three-row {
    flex-basis: 25%;
}

.boltform textarea, .boltform input {
    width: 100%;
}

@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }
    
    .boltform form {
        flex-direction: column;
    }
}




.formwrapper {
    background-color: #b8c7af; 
    padding: 20px 0px;
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    background: #55654c;
    border: none;
}

.boltforms-row button:hover {
        background: #343e2f;
    color: white;

}

.kontakt svg {
    margin-right: 10px;
}


/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}

/*----------------------------Allt under 1400px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
   
    .container {
        width: 96%; 
    } 
    .introknapp {
        margin-top: 1em;
    }
    .slick-initialized .slick-slide, .slick-substitute>div {
        min-height: 570px;
    }
  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
/*Containers*/
    .container.sml {
        width: 96%; 
    } 
    
/*Footer*/   
    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 
    
     .boltforms-l-left-row {
        max-width: 100%;
    }
}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    .logo img {
        max-height: 18px;
    }
    .slick-title {
        font-size: 0.9em;
    }
    .facebooktitel {
        font-size: 0.9em;
    }

}



/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
